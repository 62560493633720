import React from 'react';
import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import Container from '@mui/material/Container';
import {Divider, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer} from '@mui/material';
import {navigate} from '@reach/router';
import icon from '../../assets/icon-72x72.png';
import MakeupListIcon from '../svg/MakeupListIcon';
import ClientsDatabaseIcon from '../svg/ClientsDatabaseIcon';
import ArchiveIcon from '../svg/ArchiveIcon';
import BlacklistIcon from '../svg/BlacklistIcon';
import ReserveListIcon from '../../assets/make-up.png';

const PREFIX = 'Layout';

const classes = {
    grid: `${PREFIX}-grid`,
    footer: `${PREFIX}-footer`,
    archive_icon: `${PREFIX}-archive_icon`,
    appIconContainer: `${PREFIX}-appIconContainer`,
    appIcon: `${PREFIX}-appIcon`,
    toolbar: `${PREFIX}-toolbar`,
    toolbarIcon: `${PREFIX}-toolbarIcon`,
    appBar: `${PREFIX}-appBar`,
    appBarShift: `${PREFIX}-appBarShift`,
    menuButtonHidden: `${PREFIX}-menuButtonHidden`,
    title: `${PREFIX}-title`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    drawerPaperClose: `${PREFIX}-drawerPaperClose`,
    appBarSpacer: `${PREFIX}-appBarSpacer`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`,
    paper: `${PREFIX}-paper`,
    fixedHeight: `${PREFIX}-fixedHeight`,
    menuIcon: `${PREFIX}-menuIcon`,
    gridChild: `${PREFIX}-gridChild`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [`& .${classes.grid}`]: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        paddingLeft: 32,
        paddingRight: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },

    [`& .${classes.footer}`]: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },

    [`& .${classes.archive_icon}`]: {
        marginLeft: 'auto',
    },

    [`& .${classes.appIconContainer}`]: {
        marginRight: '8px',
    },

    [`& .${classes.appIcon}`]: {
        width: '100%',
        height: '100%',
    },

    [`& .${classes.toolbar}`]: {
        paddingRight: 24, // keep right padding when drawer closed
    },

    [`& .${classes.appBar}`]: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    [`& .${classes.appBarShift}`]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    [`& .${classes.menuButtonHidden}`]: {
        display: 'none',
    },

    [`& .${classes.title}`]: {
        flexGrow: 1,
    },

    [`& .${classes.appBarSpacer}`]: theme.mixins.toolbar,

    [`& .${classes.content}`]: {
        flexGrow: 1,
        transition: '.3s padding-left',
        paddingTop: 32,
        display: 'flex',
        flexDirection: 'column',
    },

    [`& .${classes.container}`]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },

    [`& .${classes.fixedHeight}`]: {
        height: 240,
    },

    [`& .${classes.gridChild}`]: {
        flexGrow: 1,
    },
}));

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({theme}) => ({
    [`& .${classes.drawerPaper}`]: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    [`& .${classes.drawerPaperClose}`]: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },

    [`& .${classes.menuIcon}`]: {
        height: 32,
        width: 32,
    },

    [`& .${classes.toolbarIcon}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
}));


export interface LayoutProps {
    children: any,
    className?: string,
}

const drawerWidth = 240;

const Layout: React.FunctionComponent<LayoutProps> = ({ children, className }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <Root className={className}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={() => setOpen(true)}
                        className={clsx(open && classes.menuButtonHidden)}
                        size="large">
                        <Icon>menu</Icon>
                    </IconButton>
                    <Icon className={classes.appIconContainer}>
                        <img src={icon} alt="" className={classes.appIcon}/>
                    </Icon>
                    <Typography variant="h6" color="inherit" noWrap onClick={() => navigate('/')}>
                        Magda Calendar
                    </Typography>
                    <div style={{ marginLeft: "auto" }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => navigate(-1)}
                            size="large">
                            <Icon>chevron_left</Icon>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <StyledSwipeableDrawer
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
                }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={() => setOpen(false)} size="large">
                        <Icon>chevron_left</Icon>
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <ListItem button onClick={() => navigate('/')}>
                        <ListItemIcon>
                            <img src={ReserveListIcon} className={classes.menuIcon}/>
                        </ListItemIcon>
                        <ListItemText primary="Makijaże"/>
                    </ListItem>

                    <ListItem button onClick={() => navigate('/reserve-list')}>
                        <ListItemIcon>
                            <MakeupListIcon className={classes.menuIcon}/>
                        </ListItemIcon>
                        <ListItemText primary="Lista rezerwowa"/>
                    </ListItem>

                    <ListItem button onClick={() => navigate('/archive')}>
                        <ListItemIcon>
                            <ArchiveIcon className={classes.menuIcon}/>
                        </ListItemIcon>
                        <ListItemText primary="Archiwum"/>
                    </ListItem>

                    <ListItem button onClick={() => navigate('/client')}>
                        <ListItemIcon>
                            <ClientsDatabaseIcon className={classes.menuIcon}/>
                        </ListItemIcon>
                        <ListItemText primary="Baza klientów"/>
                    </ListItem>

                    <ListItem button onClick={() => navigate('/blacklist')}>
                        <ListItemIcon>
                            <BlacklistIcon className={classes.menuIcon}/>
                        </ListItemIcon>
                        <ListItemText primary="Czarna lista"/>
                    </ListItem>
                </List>
            </StyledSwipeableDrawer>
            <main className={classes.content}>
                <Container className={classes.grid} maxWidth="md">
                    <Grid className={classes.gridChild} container direction="column" spacing={4}>
                        {children}
                    </Grid>
                </Container>
            </main>
            <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    Magda calendar
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    Crafted with <span role="img" aria-label="icon" aria-labelledby="mis">💖</span> by Mateusz Tomkowiak
                </Typography>
            </footer>
        </Root>
    );
}

export default Layout;
