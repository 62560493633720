import React from "react";
import {SearchClientData} from "../../redux/modules/clients/clients.interfaces";
import dayjs from "../../libs/dayjs";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {CameraIcon} from "../ListItem/ListItem.styled";
import makeupLesson from "../../assets/makeup-lesson.svg";
import trialMakeup from "../../assets/trial-makeup.svg";
import fullBride from "../../assets/full_bride.png";
import blacklist from "../../assets/blacklist.svg";

export const ClientMetadata = ({ bride, brideAway, trialWedding, lesson, lastVisit, visitsCount }: SearchClientData) => {
    const formatDate = (date: string) => {
        return dayjs(date).format('DD.MM.YYYY');
    }
    return (
        <List sx={{ marginLeft: "-8px" }}>
            {lastVisit && (
                <ListItem sx={{ paddingInlineStart: 0 }}>
                    <CameraIcon><img src={blacklist} alt="" style={{ width: 24, height: 24 }} /></CameraIcon>
                    Ostatni makijaż: {formatDate(lastVisit)}<br />Ilość wizyt: {visitsCount}
                </ListItem>
            )}
            {bride && !brideAway && (
                <ListItem sx={{ paddingInlineStart: 0 }}>
                    <CameraIcon><img src={fullBride} alt="Makijaż ślubny" style={{ width: 24, height: 24 }} /></CameraIcon>
                    Makijaż ślubny: {formatDate(bride)}
                </ListItem>
            )}
            {brideAway && (
                <ListItem sx={{ paddingInlineStart: 0 }}>
                    <CameraIcon><img src={fullBride} alt="Makijaż ślubny" style={{ width: 24, height: 24 }} /></CameraIcon>
                    Zlecenie z dojazdem: {formatDate(brideAway)}
                </ListItem>
            )}
            {trialWedding && (
                <ListItem sx={{ paddingInlineStart: 0 }}>
                    <CameraIcon><img src={trialMakeup} alt="Makijaż próbny ślubny" style={{ width: 24, height: 24 }} /></CameraIcon>
                    Makijaż próbny: {formatDate(trialWedding)}
                </ListItem>
            )}
            {lesson && (
                <ListItem sx={{ paddingInlineStart: 0 }}>
                    <CameraIcon><img src={makeupLesson} alt="Lekcja" style={{ width: 24, height: 24 }} /></CameraIcon>
                    Lekcja makijażu: {formatDate(lesson)}
                </ListItem>
            )}
        </List>
    )
}