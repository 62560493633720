import React, {useEffect, useMemo} from 'react'
import { RouteComponentProps, navigate } from '@reach/router';
import Layout from '../../components/Layout/Layout';
import {CircularProgress, List, Fab, Icon, Input, Typography, ListSubheader} from '@mui/material';
import ListItem from '../../components/Blacklist/ListItem/ListItem';
import { BlacklistData } from '../../redux/modules/blacklists/blacklists.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { getBlacklists } from '../../redux/modules/blacklists';
import { findAllBlacklists } from '../../redux/modules/blacklists/blacklists.thunks';
import { alpha, styled } from '@mui/material/styles';
import {getBase} from "../../redux/modules/base";
import dayjs from "../../libs/dayjs";

const PREFIX = 'ListPage';

const classes = {
  root: `${PREFIX}-root`,
  ul: `${PREFIX}-ul`,
  subheader: `${PREFIX}-subheader`,
  subheaderYear: `${PREFIX}-subheaderYear`,
  fab: `${PREFIX}-fab`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.ul}`]: {
    padding: 0,
  },

  [`& .${classes.subheader}`]: {
    color: '#000',
    fontWeight: 'bold',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },

  [`& .${classes.fab}`]: {
    position: 'fixed',
    right: '16px',
    bottom: '16px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    zIndex: 1000,
  },

  [`& .${classes.search}`]: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },

  [`& .${classes.searchIcon}`]: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
    width: '100%',
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },

  [`& .${classes.subheaderYear}`]: {
    color: 'rgba(0, 0, 0, 0.9)',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    background: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    top: '64px',
    position: 'static',
    // margin: '0 -2rem 1rem',
    margin: '0 0 1rem',
    textAlign: 'center',
    fontSize: '1.5rem',
  },
}));

const ListPage: React.FunctionComponent<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { isLoading, blacklists, blacklistsCount } = useSelector(getBlacklists);
  const { rewindId } = useSelector(getBase);
  const [query, setQuery] = React.useState('');

  const filteredBlacklist = React.useMemo<BlacklistData[]>(() => {
    if (!query) {
      return blacklists;
    }

    return blacklists.filter(item => item.firstName.trim().toLowerCase().includes(query.toLowerCase())
      || item.lastName.trim().toLowerCase().includes(query.toLowerCase())
    )
  }, [query, blacklists]);

  const blacklistGroups = useMemo(() => {
    const groups = new Set<string>();

    filteredBlacklist.forEach(blEntry => {
      const year = dayjs(blEntry.createdAt).format('YYYY');
      groups.add(year);
    });

    const newBlacklistGroups = Array.from(groups).map((year) => ({
      year,
      blacklistEntries: filteredBlacklist.filter(blEntry => dayjs(blEntry.createdAt).format('YYYY') === year)
          .sort((a, b) => {
            if (a.createdAt! > b.createdAt!) {
              return 1
            } else if (a.createdAt! < b.createdAt!) {
              return -1;
            }
            return 0;
          }),
    }));

    return newBlacklistGroups;
  }, [filteredBlacklist]);

  useEffect(() => {
    dispatch(findAllBlacklists())
  }, [dispatch]);

  useEffect(() => {
    const shouldRewind = () => {
      setTimeout(() => {
        if (rewindId) {
          const element = document.getElementById(`${rewindId}-anchor`);
          if (element) {
            element.scrollIntoView();
          }
        }
      }, 100);
    };

    shouldRewind();
  }, [rewindId]);

  const renderBlacklistGroups = () => {
    let year: string;

    return blacklistGroups.map(group => (
      <div key={`section-${group.year}`}>
        <ul className={classes.ul}>
          <ListSubheader className={classes.subheaderYear}>{group.year}</ListSubheader>
          {group.blacklistEntries.map(blEntry => (
              <ListItem
                  key={blEntry._id}
                  {...blEntry}
              />
          ))}
        </ul>
      </div>
    ));
  }

  return (
    <StyledLayout>
      { isLoading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Icon>search</Icon>
            </div>
            <Input
              value={query}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          {renderBlacklistGroups()}
          <Typography variant="subtitle2">
            Ilość osób na czarnej liście: {blacklistsCount}
          </Typography>
        </React.Fragment>
      )}
      <Fab className={classes.fab} onClick={() => navigate('/blacklist/create')}>
        <Icon>add</Icon>
      </Fab>
    </StyledLayout>
  );
}

export default ListPage
